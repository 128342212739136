import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { GlobalUserContext } from '../../globals/globals'
import { logError } from '../../util/browser'
import { StoryContainer, StoryId } from '../story/storycontainer'
import { User } from '../user/user'
import { getStorage } from './storage'

type SavePromise = Promise<SaveResult | null>

export const isSaving = (): boolean => {
    return storySaveQueueRemote.size > 0
}

function hashString(str: string): number {
    let hash = 0
    if (str.length === 0) {
        return hash
    }
    for (let i = 0; i < str.length; i++) {
        const char = str.codePointAt(i)
        hash = (hash << 5) - hash + (char ?? 0)
        hash = hash & hash // Convert to 32bit integer
    }
    return hash
}

const SaveStoryBufferMsRemote = 5000
const SaveStoryBufferMsLocal = 500

const storySaveQueueRemote: Map<
    StoryId,
    { timeoutId: number; resolve: (value: any) => void; execute: () => Promise<void> }
> = new Map()
export const storySavingQueueRemote: Map<StoryId, boolean> = new Map()
const storySaveQueueLocal: Map<
    StoryId,
    { timeoutId: number; resolve: (value: any) => void; execute: () => Promise<void> }
> = new Map()
const lastSavedStories: Map<StoryId, { serializedHash: number; timeoutId: number }> = new Map()

export function useRemoteSaveQueueStatus(): Map<StoryId, any> {
    const [queue, setQueue] = useState(1)
    useEffect(() => {
        function handleRemoteSave() {
            setQueue(queue + 1)
        }
        window.addEventListener('remoteSaveQueueUpdate', handleRemoteSave)
        return () => window.removeEventListener('remoteSaveQueueUpdate', handleRemoteSave)
    })
    return storySaveQueueRemote
}

interface SaveResult {
    newStories: StoryId[]
    newId: StoryId
}

export async function localSave(session: User, storyUpdateId: string): SavePromise {
    const updatedStory = GlobalUserContext.stories.get(storyUpdateId)
    if (!updatedStory) {
        return null
    }

    const storage = getStorage(session)

    let updatedStoryContent = GlobalUserContext.storyContentCache.get(updatedStory.id)
    if (!updatedStoryContent) {
        // if story isn't loaded yet, load it
        try {
            updatedStoryContent = await storage.getStoryContent(updatedStory)
            GlobalUserContext.storyContentCache.set(updatedStory.id, updatedStoryContent)
        } catch (error: any) {
            logError(error)
            return null
        }
    }
    const storyContainer = StoryContainer.bundle(updatedStory, updatedStoryContent)
    try {
        // Serialize and deserialize to catch any serialization errors
        StoryContainer.deserialize(storyContainer.serialize())
    } catch (error: any) {
        throw new Error(`Failed to serialize for local story save: ${error?.message ?? error}`)
    }

    const newId = await storage.saveStory(storyContainer, false).catch((error) => {
        toast(`Saving failed, will retry in a moment. Error: ${error?.message ?? error}`, {
            type: 'error',
            toastId: 'saving-failed-' + storyUpdateId,
        })
        toast.update('saving-failed-' + storyUpdateId, {
            render: `Saving failed, will retry in a moment. Error: ${error?.message ?? error}`,
            type: 'error',
        })
    })
    if (!newId) {
        return null
    }
    const newStories = [...GlobalUserContext.stories.keys()].sort(
        (a, b) =>
            (GlobalUserContext.stories.get(b)?.lastUpdatedAt.valueOf() || 0) -
            (GlobalUserContext.stories.get(a)?.lastUpdatedAt.valueOf() || 0)
    )

    storySaveQueueLocal.delete(storyUpdateId)
    return { newStories, newId }
}

export async function queueLocalSave(session: User, storyId: string): SavePromise {
    // save after a delay to avoid recurrent saving of the same story
    const inQueue = storySaveQueueLocal.get(storyId)
    if (inQueue) {
        clearTimeout(inQueue.timeoutId)
        inQueue.resolve(null)
    }
    const promise: SavePromise = new Promise((resolve, reject) => {
        const execute = () => localSave(session, storyId).then(resolve).catch(reject)
        const timeoutId = setTimeout(() => {
            execute()
        }, SaveStoryBufferMsLocal) as any
        storySaveQueueLocal.set(storyId, { timeoutId, resolve, execute })
    })
    return promise
}

async function doSave(
    session: User,
    storyUpdateId: string,
    askOverwrite?: (other: StoryContainer) => Promise<boolean>
): SavePromise {
    const updatedStory = GlobalUserContext.stories.get(storyUpdateId)
    if (!updatedStory) {
        // We can't save a story that doesn't exist
        storySavingQueueRemote.delete(storyUpdateId)
        return null
    }
    let updatedStoryContent = GlobalUserContext.storyContentCache.get(updatedStory.id)
    const storage = getStorage(session)

    if (!updatedStoryContent) {
        // if story isn't loaded yet, load it
        try {
            updatedStoryContent = await storage.getStoryContent(updatedStory)
            GlobalUserContext.storyContentCache.set(updatedStory.id, updatedStoryContent)
        } catch (error: any) {
            logError(error)
            storySavingQueueRemote.delete(storyUpdateId)
            return null
        }
    }
    if (hashString(updatedStory.serialize()) === lastSavedStories.get(updatedStory.id)?.serializedHash) {
        // Don't save if story didn't change. We only compare the metadata, not the content.
        // Serializing the content is expensive, and lastUpdatedAt is part of the metadata, so
        // we can assume any change to the content will also change the metadata.
        storySavingQueueRemote.delete(storyUpdateId)
        return null
    }

    let newId = ''
    const storyContainer = StoryContainer.bundle(updatedStory, updatedStoryContent)
    // Serialize and deserialize to catch any serialization errors
    try {
        StoryContainer.deserialize(storyContainer.serialize())
    } catch (error: any) {
        throw new Error(`Failed to serialize for remote story save: ${error?.message ?? error}`)
    }

    const beforeSave = StoryContainer.deserialize(storyContainer.serialize())

    newId = await storage.saveStory(storyContainer, true, askOverwrite).catch((error) => {
        if (!`${error}`.includes('body is too large')) queueSaving(session, storyUpdateId, askOverwrite)
        logError(error, true, 'remote save failed')
        toast(`Saving failed, will retry in a moment. Error: ${error?.message ?? error}`, {
            type: 'error',
            toastId: 'saving-failed-' + storyUpdateId,
        })
        toast.update('saving-failed-' + storyUpdateId, {
            render: `Saving failed, will retry in a moment. Error: ${error?.message ?? error}`,
            type: 'error',
        })
        storySavingQueueRemote.delete(storyUpdateId)
        throw error
    })
    const newStories = [...GlobalUserContext.stories.keys()].sort(
        (a, b) =>
            (GlobalUserContext.stories.get(b)?.lastUpdatedAt.valueOf() || 0) -
            (GlobalUserContext.stories.get(a)?.lastUpdatedAt.valueOf() || 0)
    )

    const lastSavedStory = lastSavedStories.get(updatedStory.id)
    // Saving the story can change these values, and we don't want them to invalidate
    // an otherwise identical story.
    beforeSave.metadata.remoteId = updatedStory.remoteId
    beforeSave.metadata.changeIndex = updatedStory.changeIndex
    if (lastSavedStory) {
        lastSavedStory.serializedHash = hashString(beforeSave.metadata.serialize())
        clearTimeout(lastSavedStory.timeoutId)
        lastSavedStory.timeoutId = setTimeout(() => {
            lastSavedStories.delete(updatedStory.id)
        }, 60 * 1000) as any
    } else {
        lastSavedStories.set(updatedStory.id, {
            serializedHash: hashString(beforeSave.metadata.serialize()),
            timeoutId: setTimeout(() => {
                lastSavedStories.delete(updatedStory.id)
            }, 60 * 1000) as any,
        })
    }
    storySavingQueueRemote.delete(storyUpdateId)
    // If queue is empty, remove page close warning
    if (storySaveQueueRemote.size === 0) {
        window.removeEventListener('beforeunload', unloadWarning)
    }
    window.dispatchEvent(new Event('remoteSaveQueueUpdate'))
    return { newStories, newId }
}

function unloadWarning(e: BeforeUnloadEvent) {
    e.preventDefault()
    e.returnValue =
        'Images generated are not saved and you must download any you want to keep. Are you sure you want to exit?'
}

export function queueSaving(
    session: User,
    storyId: StoryId,
    askOverwrite?: (other: StoryContainer) => Promise<boolean>
): SavePromise {
    // save after a delay to avoid recurrent saving of the same story
    const inQueue = storySaveQueueRemote.get(storyId)
    if (inQueue) {
        // The story was updated but is already in the queue to be saved.
        // We cancel the previous save and queue a new one.
        clearInterval(inQueue.timeoutId)
        inQueue.resolve(null)
    }
    // Set page close warning to prevent accidental page close
    window.addEventListener('beforeunload', unloadWarning)
    const promise: SavePromise = new Promise((resolve, reject) => {
        const execute = () => doSave(session, storyId, askOverwrite).then(resolve).catch(reject)
        const timeoutId = setInterval(() => {
            if (storySavingQueueRemote.get(storyId)) {
                // The story is already being saved. If we were to queue it again, it could
                // lead to a story conflict. Instead, we wait for the interval to elapse
                // and then try again.
                return
            }
            clearInterval(timeoutId)
            storySaveQueueRemote.delete(storyId)
            storySavingQueueRemote.set(storyId, true)
            execute()
        }, SaveStoryBufferMsRemote) as any
        storySaveQueueRemote.set(storyId, { timeoutId, resolve, execute })
        window.dispatchEvent(new Event('remoteSaveQueueUpdate'))
    })
    return promise
}

export function flushSavingQueue(): Promise<void[]> {
    const allSaved = Promise.all(
        [...storySaveQueueRemote.values()].map((inQueue) => {
            if (inQueue) {
                clearTimeout(inQueue.timeoutId)
                inQueue.resolve(null)
                inQueue.execute()
            }
        })
    )
    storySaveQueueRemote.clear()
    window.removeEventListener('beforeunload', unloadWarning)
    window.dispatchEvent(new Event('remoteSaveQueueUpdate'))
    return allSaved
}
