import { useRecoilCallback } from 'recoil'
import { Session, TrialUsageRemaining } from '../globals/state'
import { useMemo } from 'react'
import posthog, { CaptureOptions } from 'posthog-js'
import { subscriptionIsActive } from '../util/subscription'
import { getUserSetting } from '../data/user/settings'

export default function useCapture(): {
    capture: (event: string, data?: any, options?: CaptureOptions) => void
} {
    const capture = useRecoilCallback(
        ({ snapshot }) =>
            async (event: string, data: any, options?: CaptureOptions) => {
                const session = await snapshot.getPromise(Session)
                const authenticated = session.authenticated
                const anonymous = session.noAccount
                const tier = session.subscription?.tier
                const subscriptionActive = subscriptionIsActive(session.subscription)
                const language = getUserSetting(session.settings, 'uiLanguage')
                const emailVerified = session.information.emailVerified
                const trialActions = await snapshot.getPromise(TrialUsageRemaining)

                let sampleRate = 1
                // If the event name ends with :generated, we want to only
                // capture 25% of the events
                if (event.endsWith(':generated')) {
                    sampleRate = 0.25
                }

                const random = Math.random()
                if (random > sampleRate) {
                    return
                }

                // Only add subscription info if the user is authenticated
                if (!authenticated) {
                    posthog.capture(
                        event,
                        {
                            sampleRate,
                            language,
                            trialActions,
                            $set: { language },
                            ...data,
                        },
                        options
                    )
                } else {
                    posthog.capture(
                        event,
                        {
                            sampleRate,
                            authenticated,
                            anonymous,
                            tier,
                            subscriptionActive,
                            language,
                            trialActions,
                            $set: {
                                tier,
                                subscriptionActive,
                                language,
                                emailVerified,
                            },
                            ...data,
                        },
                        options
                    )
                }
            }
    )

    return useMemo(() => ({ capture }), [capture])
}
