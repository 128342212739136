import { useEffect, useRef, useState } from 'react'

import { logError } from '../util/browser'

export function fetchVersion(): Promise<Response> {
    return fetch('/version', {
        cache: 'no-store',
        headers: {
            'Content-Type': 'text/plain',
        },
    })
}

export function fetchReloadInfo(): Promise<Response> {
    return fetch('/updateReload.json', {
        cache: 'no-store',
        headers: {
            'Content-Type': 'application/json',
        },
    })
}

enum ReloadType {
    NoReload = 0,
    Reload = 1,
}

export default function useVersionCheck(interval: number = 300000): {
    available: boolean
} {
    const [newAvailable, setNewAvailable] = useState({ available: false })
    const updateReloadInfoRef = useRef<{ id: number; reload: ReloadType }[]>()

    useEffect(() => {
        const doCheck = async () => {
            try {
                // eslint-disable-next-line unicorn/no-await-expression-member
                const updateReloadInfo = await fetchReloadInfo()
                const updateReloadInfoJson = await updateReloadInfo.json()

                // If this is the first time we're getting the updateReloadInfo, set the ref
                if (!updateReloadInfoRef.current) {
                    updateReloadInfoRef.current = updateReloadInfoJson
                } else {
                    // The updateReloadInfo has changed, so we need to look at it to determine if we need to reload
                    if (updateReloadInfoRef.current.length !== updateReloadInfoJson.length) {
                        // The length of the array has changed, so we need to check all new entries
                        // and reload if any require a reload.
                        const newItems = updateReloadInfoJson.filter(
                            (n: { id: number; reload: ReloadType }) =>
                                n.id > (updateReloadInfoRef.current?.[0]?.id ?? 0)
                        )

                        if (
                            newItems.some(
                                (n: { id: number; reload: ReloadType }) => n.reload === ReloadType.Reload
                            )
                        ) {
                            setNewAvailable({ available: true })
                        }
                    }
                }
            } catch (error) {
                logError(error, false, 'failed to check for updates')
            }
        }
        doCheck()
        if (interval > 0) {
            const check = setInterval(doCheck, interval)
            return () => {
                clearInterval(check)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return newAvailable
}
